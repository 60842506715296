import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import * as XLSX from "xlsx";

import SearchWithdrawals from "../components/cusWithdrawals/SearchWithdrawals";
import TableData from "../components/cusWithdrawals/TableData";
import { useRecoilState } from "recoil";
import { useAPI } from "../services/happypay-api";
import {
  inputSearchHistorywithdraw,
  withdrawFilterStatusState,
  withdrawFilterStartState,
  withdrawFilterEndState,
  withdrawListState,
  withdrawSumState,
  withdrawSumFeeState,
} from "../states/withdraw-list";
import { authState } from "../states/auth";
import dayjs from "dayjs";
import WithdrawBalanceStat from "../components/cusWithdrawals/WithdrawBalanceStat";
import { withdrawState } from "../states/withdraw";
import moment from "moment/moment";
import { decimalTwoFormat, decimalTwoFormatToexcal } from "../utils/formats";

function WithdrawPage() {
  const [isLogin, setIsLogin] = useRecoilState(authState.isLogin);
  const [inputSearchwithdraw, setInputSearchwithdraw] = useRecoilState(inputSearchHistorywithdraw);

  const [withdrawFilterStatus, setWithdrawFilterStatus] = useRecoilState(withdrawFilterStatusState);
  const [withdrawFilterStart, setWithdrawFilterStart] = useRecoilState(withdrawFilterStartState);
  const [withdrawFilterEnd, setWithdrawFilterEnd] = useRecoilState(withdrawFilterEndState);

  const api = useAPI();

  const [withdrawSum, setWithdrawSum] = useRecoilState(withdrawSumState);
  const [withdrawSumFee, setWithdrawSumFee] = useRecoilState(withdrawSumFeeState);
  const [withdrawList, setWithdrawList] = useRecoilState(withdrawListState);

  const [totalWithdraw, setTotalWithdraw] = useRecoilState(withdrawState.totalWithdraw)
  const [totalBalance, setTotalBalance] = useRecoilState(withdrawState.totalBalance);
  const [pendingWithdraw, setPendingWithdraw] = useRecoilState(withdrawState.pendingWithdraw);
  const [withdrawAvailable, setWithdrawAvailable] = useRecoilState(withdrawState.withdrawAvailable);
  const [pendingTopup, setPendingTopup] = useRecoilState(withdrawState.pendingTopup);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api
      .balanceMerchant(localStorage.getItem("token"))
      .then((data) => {
        setWithdrawAvailable(data.balanceWithdraw);
        setTotalWithdraw(data.totalWithdraw);
      })
      .catch((error) => {
        console.error("Error fetching merchant balance:", error);
      });
  }, []);

  const callWithdrawList = async () => {
    setLoading(true)
    try {
      const formattedStart = withdrawFilterStart
        ? dayjs(withdrawFilterStart).format("YYYY-MM-DD HH:mm:ss")
        : null;
      const formattedEnd = withdrawFilterEnd
        ? dayjs(withdrawFilterEnd).format("YYYY-MM-DD HH:mm:ss")
        : null;

      const data = await api.withdrawList(
        inputSearchwithdraw,
        withdrawFilterStatus,
        formattedStart,
        formattedEnd
      )
      setWithdrawList(data);
    } catch (error) {
      console.error("Error fetching withdraw list:", error);
      alert(error.message);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    callWithdrawList();
  }, [inputSearchwithdraw, withdrawFilterStatus, withdrawFilterStart, withdrawFilterEnd]);

  const exportToExcel = () => {
    if (withdrawList.length === 0) {
      alert("No data available to export");
      return;
    }

    const data = withdrawList.map((e) => ({
      "Create Date": moment(e.dateCreated).format("DD/MM/YYYY-HH:mm:ss"),
      "Transaction Id": e.clientTransactionId,
      "Bank Account Detail": `${e.bookBankName} ${e.bookBankNumber} ${e.bankName}`,
      "Amount": parseFloat(decimalTwoFormatToexcal(e.amount)) || 0.0,
      "Fee %": parseFloat(decimalTwoFormatToexcal(e.totalFee)) || 0.0,
      "Fee with Amount": parseFloat(decimalTwoFormatToexcal(e.feeOutAmount)) || 0.0,
      "Vat 7% From Fee": parseFloat(decimalTwoFormatToexcal(e.feeOutAmount * 0.07)) || 0.0,
      "Amount with Fee": parseFloat(decimalTwoFormatToexcal(e.amountWithFee)) || 0.0,
      "Status": e.status,
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);

    const columnWidths = {};
    for (let i = 0; i < data.length; i++) {
      Object.keys(data[i]).forEach((key, colIndex) => {
        const cell = worksheet[XLSX.utils.encode_cell({ r: i + 1, c: colIndex })];
        if (cell && cell.v) {
          const cellValue = cell.v.toString();
          const currentMaxLength = columnWidths[key] || 0;
          columnWidths[key] = Math.max(currentMaxLength, cellValue.length);
        }
      });
    }

    worksheet["!cols"] = Object.keys(columnWidths).map((key) => ({
      wpx: columnWidths[key] * 10,
    }));

    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let col = range.s.c; col <= range.e.c; col++) {
      const header = XLSX.utils.encode_col(col) + "1";
      const headerValue = worksheet[header]?.v;

      if (
        ["Amount", "Fee %", "Fee with Amount", "Vat 7% From Fee", "Amount with Fee"].includes(
          headerValue
        )
      ) {
        for (let row = 2; row <= range.e.r + 1; row++) {
          const cell = XLSX.utils.encode_cell({ r: row - 1, c: col });
          if (worksheet[cell]) {
            const value = worksheet[cell].v;
            if (!isNaN(value)) {
              worksheet[cell].t = "n";
              worksheet[cell].z = "#,##0.00";
              if (!worksheet[cell].s) worksheet[cell].s = {};
              worksheet[cell].s.alignment = { horizontal: "right" };
            } else {
              worksheet[cell].t = "s";
            }
          }
        }
      }
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Withdraws");

    XLSX.writeFile(
      workbook,
      `Report Withdraws ${localStorage.getItem("name")} ${dayjs(withdrawFilterStart).format(
        "DD-MM-YYYY"
      )}-${dayjs(withdrawFilterEnd).format("DD-MM-YYYY")}.xlsx`
    );
  };

  return (
    <>
      <Stack spacing={2}>
        <Box>
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Typography variant="h4" component="h2">
                Withdraws
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <WithdrawBalanceStat />

        <Box>
          <Paper>
            <Box sx={{ p: 3 }}>
              <Typography fontSize={"1.3rem"}>Withdraws History</Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <SearchWithdrawals exportCsv={exportToExcel} />
            </Box>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
                <CircularProgress />
              </Box>
            ) : (
              <TableData />
            )}
          </Paper>
        </Box>
      </Stack >
    </>
  );
}

export default WithdrawPage;